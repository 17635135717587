import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  List as VirtualList, 
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized';                                             

import { RichTextViewer, UserType, Utils } from '@mvrb-frontend/shared-ui';
import ResponseSection from './responses';
import { CommentStatusEnum, CommentTypeEnum } from '../../../constants';
import { useGetCommentSet, useGetUsers } from '../../../hooks';
import { getUserId, isLoggedIn, isStaffUser } from '../../../utils/auth';

export const useStyles = makeStyles(theme => ({
  dense: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  accordion: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#39A9B0',
    fontSize: '18px',
    lineHeight: '32px',
    color: theme.palette.common.white,
    boxShadow: 'none',
  },
  list: {
    backgroundColor: '#F6F6F9',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderTop: 0,
    padding: 0,
  },
  listIndex: {
    opacity: 0.4,
    fontSize: '16px',
    fontWeight: 600,
    alignSelf: 'flex-start',
    paddingTop: '10px',
  },
  attachmentBtn: {
    cursor: 'initial',
  },
  transcriptBtn: {
    cursor: 'pointer',
  },
  fileIcon: {
    color: '#E67337',
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  fileText: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
    padding: 0,
    margin: '5px',
  },
  fileSize: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: 0,
    whiteSpace: 'nowrap',
    margin: '5px',
  },
  date: {
    opacity: 0.4,
    textAlign: 'right',
  },
  infoIcon: {
    color: '#fff',
    marginRight: 4,
  },
  lateLabel: {
    color: '#fff',
    paddingBottom: 0,
  },
  editBtn: {
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
}));

const isStaff = isStaffUser();
const userId = getUserId();

const PublicCommentsDetails = props => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 200,
  });

  const classes = useStyles();
  const { refs, labels, isIR, commentSetId, canAdmin, index } = props;
  const [commentSet, setCommentSet] = useState<any>();
  const [canShow, setCanShow] = useState(false);

  const { getCommentSetById } = useGetCommentSet();
  const { getUsers } = useGetUsers();

  useEffect(() => {
    (async () => {
      const commentSetDetails = await getCommentSetById(commentSetId, true);

      if (commentSetDetails) {
        setCommentSet(commentSetDetails);
      }
    })();
  }, []);

  useEffect(()=>{
    /* 
     * Only for an Information Request
     * Check if a user belongs to a comment addressedTo organization 
    */
    if(canShow === false && commentSet!=undefined){
      (async() => {
        if (isStaff === false && canAdmin === false && isLoggedIn() && isIR) {
          for (let x = 0; x < commentSet.comments.length; x++) {
            let comment = commentSet.comments[x];
            const users: any = await getUsers(comment.addressTo?.id);
  
            if (users?.length > 0) {
              users.some(user => user.id === userId) === true
                ? commentSet.comments[x].canAddResponses = true
                : commentSet.comments[x].canAddResponses = false
            }
          }
          setCommentSet(commentSet);
        }
        setCanShow(true);
      })();
    }

  }, [commentSet, canShow]);

  const hasTopic = comment => {
    const customTopicContent = JSON.parse(comment.customTopic)?.blocks
      ? JSON.parse(comment?.customTopic)
          .blocks.map(block => block.text)
          .join()
      : comment?.customTopic;
    return (
      comment.topic ||
      (customTopicContent &&
        customTopicContent.length > 0 &&
        customTopicContent !== 'No Topic')
    );
  };

  const displayResponses = comment => {
    return (
      comment.response &&
      comment.response.status === CommentStatusEnum.PUBLISHED
    );
  };

  const getCreatedDate = comment => {
    return moment.unix(comment.createdAt).format('LL');
  };

  const downloadFile = (file: any) => {
    window.open(file.generatedUrl, '_blank');
  };

  const editedByComment = (comment, commentSet) => {
    if (Math.abs(comment?.createdAt - comment?.updatedAt) < 2) {
      return false;
    }

    const updatedByStaff = (comment.updatedBy.userType === UserType.Staff || comment.updatedBy.userType === UserType.Admin);
    const updatedAfterPublished = moment(comment.updatedAt).diff(
      moment(commentSet.publishedAt),
    );

    return updatedByStaff && updatedAfterPublished >= 0;
  };

  const editedByResponse = (comment, commentSet) => {
    const response = commentSet.comments?.find(el => el.id === comment.id)
      ?.response;
    if (Math.abs(response?.createdAt - response?.updatedAt) < 2) {
      return false;
    }

    return (
      comment.response?.updatedBy?.userType === UserType.Staff &&
      comment.response?.authorId !== comment.response?.updatedBy.id
    );
  };

  const getAttachments = item => {
    return (
      <React.Fragment>
        {item?.attachments?.map(file => {
          return (
            <React.Fragment key={file.id}>
              <Grid item xs={3} />
              <Grid item xs={7}>
                <Box id={file.id} my={0.5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    ref={refs[file.id]}
                    className={classes.attachmentBtn}
                    startIcon={<DescriptionIcon className={classes.fileIcon} />}
                    onClick={() => downloadFile(file.file)}
                    endIcon={<GetAppIcon className={classes.downloadIcon} />}
                  >
                    {file.name}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  if (!commentSet && canShow===false) {
    return (
      <Box m={2} width={1}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <List className={classes.dense}>
      {commentSet!==undefined && canShow &&(
        <div style={{width:"100%", height: "700px"}}>
          <AutoSizer>
            {({width, height, measurer})=>(
              <VirtualList 
                width={width}
                height={height}
                rowHeight={cache.rowHeight}
                deferredMeasurementCache={cache}
                rowCount={commentSet.comments.length}
                rowRenderer={({key, index, style, parent})=>{
                  let comment=commentSet.comments[index]
                  return(
                    <CellMeasurer 
                      key={key} 
                      cache={cache} 
                      parent={parent} 
                      columnIndex={0}
                      rowIndex={index}
                    >
                      <React.Fragment >
                      {/* Set the style of the VirtualList (top level div) */}
                      <div style={style}>
                      <ListItem style={{ width: '100%' }}>
                        <ListItemIcon className={classes.listIndex}>
                          {index < 9 ? `0${index + 1}.` : `${index + 1}.`}
                        </ListItemIcon>

                        <Box p={2} style={{ width: '100%' }}>
                          <Grid container>
                            {hasTopic(comment) && (
                              <React.Fragment>
                                <Grid item xs={3}>
                                  <Typography variant="subtitle2">Topic</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  {comment.topic ? (
                                    <Typography variant="body1">
                                      {comment.topic.name}
                                    </Typography>
                                  ) : (
                                    <Box mt={-2.5}>
                                      <RichTextViewer value={comment.customTopic} />
                                    </Box>
                                  )}
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body1" className={classes.date}>
                                    {getCreatedDate(comment)}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            )}
                            {comment.addressTo && (
                              <React.Fragment>
                                <Grid item xs={3}>
                                  <Typography variant="subtitle2">
                                    Addressed To
                                  </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography variant="body1">
                                    {comment.addressTo.name}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            )}
                            {comment.type !== CommentTypeEnum.Audio &&
                              comment.description && (
                                <React.Fragment>
                                  <Grid item xs={3}>
                                    <Typography variant="subtitle2">
                                      {labels.comment}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Box mt={-2}>
                                      <RichTextViewer value={comment.description} />
                                    </Box>
                                  </Grid>
                                  {hasTopic(comment) ? (
                                    <Grid item xs={2} />
                                  ) : (
                                    <Grid item xs={2}>
                                      <Typography
                                        variant="body1"
                                        className={classes.date}
                                      >
                                        {getCreatedDate(comment)}
                                      </Typography>
                                    </Grid>
                                  )}
                                </React.Fragment>
                              )}
                            {comment.type !== CommentTypeEnum.Audio &&
                              !Utils.isRichTextEmpty(comment.recommendation) && (
                                <React.Fragment>
                                  <Grid item xs={3}>
                                    <Typography variant="subtitle2">
                                      {labels.recommendation}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Box mt={-2}>
                                      <RichTextViewer value={comment.recommendation} />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2} />
                                </React.Fragment>
                              )}
                            {comment.type === CommentTypeEnum.Audio && (
                              <React.Fragment>
                                {comment.audio && (
                                  <React.Fragment>
                                    <Grid item xs={3}>
                                      <Typography variant="subtitle2">Comment</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Box my={0.5}>
                                        <audio controls style={{ width: '100%' }}>
                                          <source
                                            type={comment.audio.mimetype}
                                            src={comment.audio.generatedUrl}
                                          />
                                          Audio comment cannot be played in this browser
                                        </audio>
                                      </Box>
                                    </Grid>
                                    {hasTopic(comment) ? (
                                      <Grid item xs={2} />
                                    ) : (
                                      <Grid item xs={2}>
                                        <Typography
                                          variant="body1"
                                          className={classes.date}
                                        >
                                          {getCreatedDate(comment)}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </React.Fragment>
                                )}
                                {comment.transcript && (
                                  <React.Fragment>
                                    <Grid item xs={3} />
                                    <Grid item xs={7}>
                                      <Box my={0.5}>
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          size="large"
                                          className={classes.transcriptBtn}
                                          startIcon={
                                            <DescriptionIcon
                                              className={classes.fileIcon}
                                            />
                                          }
                                          onClick={() => downloadFile(comment.transcript)}
                                          endIcon={
                                            <GetAppIcon
                                              className={classes.downloadIcon}
                                            />
                                          }
                                        >
                                          <Typography
                                            className={classes.fileText}
                                            variant="body1"
                                          >
                                            {comment.transcript.filename ||
                                              comment.transcript.originalname}
                                          </Typography>
                                          <Typography
                                            className={classes.fileSize}
                                            variant="body1"
                                          >
                                            {`(${Utils.getFileSize(
                                              comment.transcript.fileSize,
                                            )})`}
                                          </Typography>
                                        </Button>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={2} />
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                            {displayResponses(comment) && (
                              <ResponseSection
                                refs={refs}
                                responseTree={comment.response}
                                isIR={isIR}
                                commentSet={commentSet}
                                commentRow={comment}
                                canAddResponses={
                                  (isStaff ===  true || canAdmin === true)
                                    ? true
                                    : comment?.canAddResponses
                                }
                              />
                            )}
                            {getAttachments(comment)}
                            {editedByComment(comment, commentSet) && (
                              <Grid item xs={12}>
                                <Typography variant="body1" className={classes.date}>
                                  Comment was edited by {comment.updatedBy?.firstName}
                                  &nbsp;
                                  {comment.updatedBy?.lastName} on&nbsp;
                                  {moment.unix(comment.updatedAt).format('MMMM Do, YYYY')}
                                  &nbsp; at&nbsp;
                                  {moment.unix(comment.updatedAt).format('HH:mm')}
                                </Typography>
                              </Grid>
                            )}
                            &nbsp;
                            {editedByResponse(comment, commentSet) && (
                              <Grid item xs={12}>
                                <Typography variant="body1" className={classes.date}>
                                  Response was edited by&nbsp;
                                  {comment.response?.updatedBy?.firstName}&nbsp;
                                  {comment.response?.updatedBy?.lastName} on&nbsp;
                                  {moment
                                    .unix(comment.response?.updatedAt)
                                    .format('MMMM Do, YYYY')}
                                  &nbsp; at&nbsp;
                                  {moment
                                    .unix(comment.response?.updatedAt)
                                    .format('HH:mm')}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </ListItem>
                      </div>
                      <Divider />
                      </React.Fragment>
                    </CellMeasurer>
                  )
                }}
                scrollToIndex={index}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </List>
  );
};

PublicCommentsDetails.propTypes = {
  commentSetId: PropTypes.string.isRequired,
  refs: PropTypes.any,
  labels: PropTypes.any.isRequired,
  isIR: PropTypes.bool.isRequired,
  canAdmin: PropTypes.any,
  index: PropTypes.number,
};

export default PublicCommentsDetails
