import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { Link as MuiLink } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import CrownConsultationModal from './modals/crownConsultationModal';
import DownloadDropdownMenu from './downloadDropdownMenu';
import PublicComments from './commentSections/publicComments';
import DraftComments from './commentSections/draftComments';
import AttachmentsList from './commentSections/attachmentsList';
import { useReviewDownloads } from '../../hooks';
import { useGetCommentSet } from '../../hooks';

import { CommentOrigin, ReviewType } from '@mvrb-frontend/shared-ui';

import { CommentStatusEnum, ReviewStatusEnum } from '../../constants';
import { getUser, isAdminUser, isStaffUser } from '../../utils/auth';
import CommentSetModel from 'ors-frontend/src/components/comments/CommentSetModel';
import Cookies from 'js-cookie';

const user = getUser();
const isStaff = isStaffUser();
const isAdmin = isAdminUser();

const useStyles = makeStyles(theme => ({
  dense: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
    backgroundColor: '#F6F6F9',
  },
  link: {
    fontWeight: 'normal',
    color: '#009B58',
    cursor: 'pointer',
  },
  linkIcon: {
    position: 'relative',
    bottom: '-5px',
  },
  commentBox: {
    minHeight: '210px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const DetailsComments = props => {
  const classes = useStyles();
  const {
    reviewId,
    isPrinting,
    commentDueAt,
    responseDueAt,
    reviewStatus,
    reviewType,
    labels,
    commentsPeriodOpen,
    allowSubmitComments,
    allowSubmitResponses,
    procedureRules,
    hyperLinkTitle,
    url,
    canAdminEdit,
    isProponent
  } = props;
  const [totalComments, setTotalComments] = useState(0);
  const [totalAttachments, setTotalAttachments] = useState(0);
  const [modal, setModal] = useState<{
    open: boolean;
    origin: CommentOrigin | null;
    callback: ((o: CommentOrigin) => void) | null;
  }>({ open: false, origin: null, callback: null });
  const [publishedCommentSets, setPublishedCommentSets] = useState<
    CommentSetModel[]
  >([]);
  const [draftCommentSets, setDraftCommentSets] = useState<CommentSetModel[]>(
    [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const [attachmentExpanded, setAtachmentExpanded] = useState<{commentSetId:String, index:Number}>(undefined);
  const [isDisabled, setIsDisabled] = useState(false);

  const { downloadComments } = useReviewDownloads();
  const {
    isFetching: isFetchingCommentSet,
    getCommentSetsSummary,
  } = useGetCommentSet();
  const [ fromComments, setFromComments ] = useState<boolean>(true);

  const refs = publishedCommentSets.reduce((acc, commentSet) => {
    commentSet.comments.forEach(comment => {
      comment.attachments?.forEach(file => {
        if (!acc[file.id]) {
          acc[file.id] = React.createRef();
        }
      });
    });
    return acc;
  }, {});

  useEffect(() => {
    (async () => {
      const commentSets = await getCommentSetsSummary(reviewId, !user);

      if (commentSets) {
        const totalComments = commentSets.reduce(
          (acc: number, cs) => (acc += cs.comments.length),
          0,
        );

        const totalAttachments = commentSets.reduce((acc: number, cs) => {
          if (cs.status !== 'draft') {
            cs.comments.forEach(comment => {
              acc += comment.attachments?.length || 0;
              acc += comment.response?.length || 0;
            });
          }
          return acc;
        }, 0);
        const publishedCS = commentSets.filter(
          cs =>
            cs.status === CommentStatusEnum.PUBLISHED && cs.comments.length > 0,
        );

        const draftCS: CommentSetModel[] = commentSets.filter(
          cs =>
            cs.status === CommentStatusEnum.DRAFT &&
            ((isStaff || (isAdmin&&canAdminEdit)) || cs.author.id === user.id),
        );

        setDraftCommentSets(draftCS);
        setPublishedCommentSets(publishedCS);
        setTotalComments(totalComments);
        setTotalAttachments(totalAttachments);
      }
    })();
  }, []);

  useEffect(()=>{
    if(user && !(isStaff || (isAdmin && canAdminEdit)) && (isProponent===false)){
      if(reviewType !== ReviewType.InformationRequest){
        setIsDisabled(true);
      }
    }
  },[isProponent]);

  const openModal = (
    origin: CommentOrigin,
    callback: (o: CommentOrigin) => void,
  ) => {
    if (
      (reviewId && !localStorage.getItem(`REVIEW_${reviewId}`)) ||
      (reviewId && !user)
    ) {
      setModal({ open: true, origin, callback });
      localStorage.setItem(`REVIEW_${reviewId}`, 'seen');
    } else {
      callback(origin);
    }
  };

  const openAddCommentsScreen = (origin: CommentOrigin) => {
    if ((!isStaff && !isAdmin) && user.organizations?.length === 1) {
      const organizationId = user.organizations[0].id;

      if (origin === CommentOrigin.Online) {
        navigate(
          `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/manage-comments/organization/${organizationId}/author/${user.id}/${isStaff || (isAdmin&&canAdminEdit)}`,
        );
      } else {
        navigate(
          `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/upload-comments/${organizationId}/${user.id}`,
        );
      }
    } else {
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/select-comments-reviewer/${origin}`,
        {state:{canAdminEdit: canAdminEdit}}
      );
    }
  };

  const openAddResponsesScreen = (origin: CommentOrigin) => {
    if (isStaff || (isAdmin && canAdminEdit)) {
      Cookies.set('canAdmin', canAdminEdit);
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/select-response-proponent/${origin}`,
      );
    } else if (origin === CommentOrigin.Import) {
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/upload-responses/${user.id}`,
      );
    } else {
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/manage-responses/proponent/${user.id}`,
      );
    }
  };

  const onCrownConsultationConfirm = () => {
    if (!user) {
      if(fromComments){
        navigate(
          reviewId && modal.origin
            ? `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/select-comments-reviewer/${modal.origin}`
            : `${process.env.GATSBY_ORS_BASE_URL}`,
        );
      }
      else{
        Cookies.set('reviewType', reviewType);
        Cookies.set('reviewId', reviewId);
        navigate(
            `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/redirect`
        );
      }
    } else if (modal.callback && modal.origin) {
      modal.callback(modal.origin);
    }
    setModal({ open: false, origin: null, callback: null });
  };

  const downloadCommentsTemplate = () => {
    if(reviewType !== ReviewType.InformationRequest){
      window.open(process.env.GATSBY_COMMENTS_EXCEL_TEMPLATE, '_self');
    }else{
      window.open(process.env.GATSBY_COMMENTS_EXCEL_TEMPLATE_IR, '_self');
    }
  };

  const downloadCurrentComments = async () => {
    const base64File = await downloadComments(reviewId, '.xlsx', true);
    const linkSource = `data:application/xlsx;base64,${base64File}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `Proponent Response Table.xlsx`;

    downloadLink.click();
  };

  const toggleExpanded = (commentSetId, isExpanded) => {
    let newExpanded = [...expanded];

    if (isExpanded) {
      newExpanded.push(commentSetId);
    } else {
      newExpanded = newExpanded.filter(i => i !== commentSetId);
    }

    setExpanded(newExpanded);
  };

  const updateExpandedAttachment = (commentSetId: string, index:Number)=>{
    if(!attachmentExpanded){
      setAtachmentExpanded({commentSetId: commentSetId, index: index});
    }
    else if(attachmentExpanded?.index!==index){
      setAtachmentExpanded({commentSetId: commentSetId, index: index});
    }
  }

  return (
    <Box pb={4}>
      <Box display="flex">
        <Typography variant="h3">
        {/*   {(isStaff ||(isAdmin && canAdminEdit)) ? 'Review Summary' : 'Comments'} */}
          Review
        </Typography>
      </Box>

      {isFetchingCommentSet && <LinearProgress />}

      <Box my={2}>
        <Box className="noPrint">
          <Card elevation={0} className={classes.card}>
            <Box mx={4} my={3}>
              {reviewStatus !== ReviewStatusEnum.COMPLETED && (
                <Box className="noPrint">
                  {allowSubmitComments && (
                    <Box my={2}>
                      <Typography variant="h4" className={classes.dense}>
                        {labels.submitCommentsOnlineTitle}
                      </Typography>
                    </Box>
                  )}

                  {allowSubmitComments && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <Box p={3} className={classes.commentBox}>
                            <Box>
                              <Typography variant="subtitle1">
                                {labels.submitCommentsOnline}
                              </Typography>
                              <Typography variant="body1">
                                {labels.submitCommentsOnlineBox}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                endIcon={<AddIcon />}
                                onClick={() =>
                                  openModal(
                                    CommentOrigin.Online,
                                    openAddCommentsScreen,
                                  )
                                }
                              >
                                {labels.addCommentsOnlineText}
                              </Button>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <Box p={3} className={classes.commentBox}>
                            <Box>
                              <Typography variant="subtitle1">
                                Work Offline and Submit Later
                              </Typography>
                              <Typography variant="body1">
                                Download our empty{' '}
                                <MuiLink
                                  className={classes.link}
                                  onClick={downloadCommentsTemplate}
                                >
                                  Excel comment table
                                  <GetAppIcon className={classes.linkIcon} />
                                </MuiLink>
                                so you can work offline and submit your
                                responses when you are ready.
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                endIcon={<PublishIcon />}
                                onClick={() =>
                                  openModal(
                                    CommentOrigin.Import,
                                    openAddCommentsScreen,
                                  )
                                }
                              >
                                {labels.uploadCommentsButton}
                              </Button>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    </Grid>
                  )}

                  {reviewStatus !== ReviewStatusEnum.COMPLETED &&
                    allowSubmitResponses && (
                      <Box>
                        <Box my={2}>
                          <Typography variant="h4" className={classes.dense}>
                            Ready to Respond?
                          </Typography>
                        </Box>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Card>
                              <Box p={3} className={classes.commentBox}>
                                <Box>
                                  <Typography variant="subtitle1">
                                    Submit your Responses online
                                  </Typography>
                                  <Typography variant="body1">
                                    {labels.submitResponsesOnlineBox}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    endIcon={<AddIcon />}
                                    disabled={!publishedCommentSets.length || isDisabled}
                                    onClick={() => {
                                      setFromComments(false);
                                      openModal(
                                        CommentOrigin.Online,
                                        openAddResponsesScreen,
                                      )
                                    }
                                    }
                                  >
                                    {labels.submitResponsesOnline}
                                  </Button>
                                </Box>
                              </Box>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Card>
                              <Box p={3} className={classes.commentBox}>
                                <Box>
                                  <Typography variant="subtitle1">
                                    Work Offline and Submit Later
                                  </Typography>
                                  <Typography variant="body1">
                                    Begin by{' '}
                                    <MuiLink
                                      onClick={downloadCurrentComments}
                                      className={classes.link}
                                    >
                                      downloading all comments as Excel here
                                      <GetAppIcon
                                        className={classes.linkIcon}
                                      />
                                    </MuiLink>
                                    so you can work offline and submit your
                                    responses when you are ready.
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    endIcon={<PublishIcon />}
                                    disabled={!publishedCommentSets.length || isDisabled}
                                    onClick={() =>
                                      openModal(
                                        CommentOrigin.Import,
                                        openAddResponsesScreen,
                                      )
                                    }
                                  >
                                    Upload Completed Excel File
                                  </Button>
                                </Box>
                              </Box>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          </Card>
        </Box>

        {/** Modal Box */}
        <CrownConsultationModal
          isDialogOpen={modal.open}
          onConfirm={onCrownConsultationConfirm}
          procedureRules={procedureRules}
          hyperLinkTitle={hyperLinkTitle}
          url={url}
        />

        {/** Comments Attachments */}
        <Box my={2}>
          <Card elevation={0} className={classes.card}>
            <Box mx={4} mt={3} mb={2}>
              {totalAttachments > 0 ? (
                <AttachmentsList
                  commentSets={publishedCommentSets}
                  attachmentsSize={totalAttachments}
                  refs={refs}
                  labels={labels}
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                  updateExpandedAttachment={updateExpandedAttachment}
                />
              ) : (
                <Typography variant="body1">{labels.noAttachments}</Typography>
              )}
            </Box>
          </Card>
        </Box>

        {/** Draft Comments */}
        {draftCommentSets.length > 0 && (
          <DraftComments
            commentSets={draftCommentSets}
            reviewId={reviewId}
            commentDueAt={commentDueAt}
            responseDueAt={responseDueAt}
            lateComment={!commentsPeriodOpen}
            isStaff={isStaff||(isAdmin&&canAdminEdit)}
          />
        )}

        {/** Public comments */}
        {!isFetchingCommentSet && publishedCommentSets.length === 0 ? (
          <Box my={2}>
            <Card elevation={0} className={classes.card}>
              <Box mx={4} my={3}>
                <Typography variant="body1" className={classes.dense}>
                  {labels.itemNoComments}
                </Typography>
              </Box>
            </Card>
          </Box>
        ) : (
          <PublicComments
            labels={labels}
            commentSets={publishedCommentSets}
            isPrinting={isPrinting}
            refs={refs}
            isIR={reviewType === ReviewType.InformationRequest}
            isStaff={isStaff||(isAdmin&&canAdminEdit)}
            reviewId={reviewId}
            expanded={expanded}
            toggleExpanded={toggleExpanded}
            indexAttachment={attachmentExpanded}
            openAttachment={setAtachmentExpanded}
          />
        )}
        {publishedCommentSets.length > 0 && (
              <Box style={{ flexGrow: 12, textAlign: 'right' }} className="noPrint">
                <DownloadDropdownMenu
                  reviewId={reviewId}
                  totalComments={totalComments}
                  hasAttachments={totalAttachments > 0}
                />
              </Box>
        )}
      </Box>
    </Box>
  );
};

DetailsComments.propTypes = {
  reviewId: PropTypes.string.isRequired,
  isPrinting: PropTypes.bool,
  commentDueAt: PropTypes.number,
  responseDueAt: PropTypes.number,
  reviewStatus: PropTypes.string,
  reviewType: PropTypes.string,
  labels: PropTypes.any.isRequired,
  commentsPeriodOpen: PropTypes.bool,
  allowSubmitResponses: PropTypes.bool,
  allowSubmitComments: PropTypes.bool,
  procedureRules: PropTypes.string,
  hyperLinkTitle: PropTypes.string,
  url: PropTypes.string,
  canAdminEdit: PropTypes.any,
  isProponent: PropTypes.any,
};

export default DetailsComments;
